import './style.css';
import {Map, View, Feature} from 'ol';
import TileLayer from 'ol/layer/Tile';
import {Vector as VectorL} from 'ol/layer' ;
import {Vector as Vectors} from 'ol/source';
import {Point} from 'ol/geom';
import {fromLonLat} from 'ol/proj';
import {Style, Icon, Text, Fill, Stroke} from 'ol/style';
import {OSM} from 'ol/source';

const map = new Map({
  target: 'map',
  layers: [
    new TileLayer({
      opacity: 0.6,
      source: new OSM()
    }),
    new VectorL({
      source: new Vectors({
          features: [
              new Feature({
                  geometry: new Point(fromLonLat([3.8738, 50.7692])),
              })
          ]
      }),
      style: new Style({
          image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: 'fraction',
            anchorYUnits: 'fraction',
            src: 'img/marker.svg'
          }),
          text: new Text({
            text: 'Oogartsen\nGeraardbergen',
            font: '20px bold Calibri,sans-serif',
            overflow: true,
            fill: new Fill({
              color: '#9c813b',
            }),
            stroke: new Stroke({
              color: 'white',
              width: 2
            }),
            offsetY: 25
          })
        })
  })
  ],
  view: new View({
    center: fromLonLat([3.8738, 50.7692]),
    minZoom: 14,
    maxZoom: 20,
    zoom: 16
  })
});

// Change the mouse cursor to a pointer when the mouse is hovering over a feature
map.on('pointermove', event => {
  if (!event.dragging) {
    map.getTargetElement().style.cursor = map.hasFeatureAtPixel(map.getEventPixel(event.originalEvent)) ? 'pointer' : '';
  }
});

// Open address in google maps new tab
map.on('singleclick', event => {
  if (map.hasFeatureAtPixel(event.pixel) === true) {
    window.open("https://goo.gl/maps/hnZDy9u5q6pHjXhK6")
  }
});
